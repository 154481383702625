import { combineReducers } from "@reduxjs/toolkit";
import { reducer as attributeSetReducer } from "./slices/attributeSet";
import reducer from "./slices/questionSlice";
import images from "./slices/imagesSlice";
import { reducer as questionsHome } from "./slices/questionsHome";
import { reducer as imagesManager } from "./slices/imagesManager";

const combinedReducer = combineReducers({
  attributeSet: attributeSetReducer,
  configAttributes: reducer,
  images,
  questionsHome,
  imagesManager,
});

const rootReducer = (state, action) => {
  return combinedReducer(state, action);
};

export default rootReducer;

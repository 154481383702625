import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api/api";

const initialState = {
  levels: { status: "idle", data: [], error: null },
  worlds: { status: "idle", data: [], error: null },
  games: { status: "idle", data: [], error: null },
  questions: { status: "idle", data: [], error: null },
};
const resetLevels = (state) => {
  state.worlds.status = "idle";
  state.worlds.error = null;
  state.worlds.data = [];

  state.games.status = "idle";
  state.games.error = null;
  state.games.data = [];

  state.questions.status = "idle";
  state.questions.error = null;
  state.questions.data = [];
};

const resetWords = (state) => {
  state.games.status = "idle";
  state.games.error = null;
  state.games.data = [];

  state.questions.status = "idle";
  state.questions.error = null;
  state.questions.data = [];
};

const resetGames = (state) => {
  state.questions.status = "idle";
  state.questions.error = null;
  state.questions.data = [];
};

export const fetchLevels = createAsyncThunk("questionsHome/fetchLevels", async () => {
  let data;
  try {
    const response = await API.get("/levels");

    data = await response.data.payload;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const fetchWorlds = createAsyncThunk("questionsHome/fetchWorlds", async (param) => {
  let data;
  try {
    const response = await API.get(`/worlds/${param}`);
    data = await response.data.payload;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const fetchGames = createAsyncThunk("questionsHome/fetchGames", async (param) => {
  let data;

  try {
    const response = await API.get(`/games/${param}`);

    data = await response.data.payload;
    if (response.status === 200) {
      return { data, styleId: param };
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const fetchQuestions = createAsyncThunk("questionsHome/fetchQuestions", async (param) => {
  let data;

  try {
    const response = await API.get(`/questions/${param}`);
    data = await response.data.payload;
    if (response.status === 200) {
      return { data, styleId: param };
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const deleteQuestion = createAsyncThunk("questionsHome/deleteQuestion", async (param) => {
  let data;

  try {
    const response = await API.delete(`/question/${param}`);
    data = await response.data.payload;
    if (response.status === 200) {
      return param;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const updateQuestionsOrder = createAsyncThunk(
  "questionsHome/updateQuestionsOrder",
  async (param, thunkAPI) => {
    const { questions, game } = param;
    await Promise.all(
      questions.map((question, index) =>
        API.post(`/question/${question.id}`, {
          questionStyle: "" + parseInt(question.question_style.id, 10),
          game: "" + game,
          text: question.text,
          orderQuestion: question.order_question,
        })
      )
    );
    thunkAPI.dispatch(fetchQuestions(game));
  }
);

const slice = createSlice({
  name: "questionsHome",
  initialState,
  reducers: {
    moveQuestion: (state, action) => {},
  },
  extraReducers: {
    [fetchLevels.pending]: (state) => {
      state.levels.status = "loading";
    },
    [fetchLevels.fulfilled]: (state, action) => {
      state.levels.status = "succeeded";
      state.levels.data = action.payload?.map((level) => ({
        value: level.id,
        option: level.name,
      }));
      state.levels.error = null;
      resetLevels(state);
    },
    [fetchLevels.rejected]: (state, action) => {
      state.levels.status = "failed";
      state.levels.error = action.payload;
      resetLevels(state);
    },

    [fetchWorlds.pending]: (state) => {
      state.worlds.status = "loading";
    },
    [fetchWorlds.fulfilled]: (state, action) => {
      state.worlds.status = "succeeded";
      state.worlds.data = action.payload?.map((world) => ({
        id: world.id,
        option: world.name,
      }));
      state.worlds.error = null;
      resetWords(state);
    },
    [fetchWorlds.rejected]: (state, action) => {
      state.worlds.status = "failed";
      state.worlds.data = [];
      state.worlds.error = action.payload;
      resetWords(state);
    },

    [fetchGames.pending]: (state) => {
      state.games.status = "loading";
    },
    [fetchGames.fulfilled]: (state, action) => {
      state.games.status = "succeeded";
      state.games.data = action.payload?.data?.map((game) => ({
        id: game.id,
        option: game.name,
      }));
      resetGames(state);
    },
    [fetchGames.rejected]: (state, action) => {
      state.games.status = "failed";
      state.games.data = [];
      state.games.error = action.payload;
      resetGames(state);
    },
    [fetchQuestions.pending]: (state) => {
      state.questions.status = "loading";
    },
    [fetchQuestions.fulfilled]: (state, action) => {
      state.questions.status = "succeeded";
      state.questions.data = action.payload.data.sort((a, b) =>
        a.order_question > b.order_question ? 1 : -1
      );
    },
    [fetchQuestions.rejected]: (state, action) => {
      state.questions.status = "failed";
      state.questions.data = [];
      state.questions.error = action.payload;
    },
    [deleteQuestion.fulfilled]: (state, action) => {
      state.questions.data = state.questions.data.filter(
        (question) => question.id !== action.payload
      );
    },
    [updateQuestionsOrder.pending]: (state, action) => {
      state.questions.status = "loading";
    },
  },
});

export const reducer = slice.reducer;
export const { moveQuestion } = slice.actions;

export default slice;

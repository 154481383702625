import React, { Suspense, Fragment, lazy } from "react";
import TuneIcon from "@mui/icons-material/Tune";
import QuizIcon from "@mui/icons-material/Quiz";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import StyleIcon from "@mui/icons-material/Style";
import Loading from "./components/Loading/Loading";
import { Navigate, Route, Routes } from "react-router-dom";
import GuestGuard from "./components/GuestGuard";
import AuthGuard from "./components/AuthGuard";
import Layout from "./layout/Layout";
export const RenderRoutes = ({ routes }) => (
  <Suspense fallback={<Loading />}>
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const MainLayout = route.layout || Fragment;
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            element={
              <Guard>
                <MainLayout>
                  <Component />
                </MainLayout>
              </Guard>
            }
          />
        );
      })}
    </Routes>
  </Suspense>
);
export const routes = [
  {
    path: "/login",
    guard: GuestGuard,
    component: lazy(() => import("./views/Login")),
  },
  {
    path: "/",
    guard: AuthGuard,
    layout: Layout,
    component: () => <Navigate to="/questions-home" />,
  },
  {
    path: "/questions-home",
    guard: AuthGuard,
    layout: Layout,
    icon: <HomeWorkIcon />,
    label: "Questions Home",
    component: lazy(() => import("./views/QuestionsHome")),
  },
  {
    path: "/config-attributes",
    guard: AuthGuard,
    layout: Layout,
    icon: <TuneIcon />,
    label: "Attributes config",
    component: lazy(() => import("./views/ConfigAttributes")),
  },
  {
    path: "/question-generator",
    guard: AuthGuard,
    layout: Layout,
    icon: <QuizIcon />,
    label: "Question page",
    component: lazy(() => import("./views/Questions")),
  },
  {
    path: "/images-manager",
    guard: AuthGuard,
    layout: Layout,
    icon: <ImageSearchIcon />,
    label: "Images manager",
    component: lazy(() => import("./views/ImagesManager")),
  },
  {
    path: "/questions-style",
    guard: AuthGuard,
    layout: Layout,
    icon: <StyleIcon />,
    label: "Questions by style",
    component: lazy(() => import("./views/QuestionsStyle")),
  },
];
export default routes;

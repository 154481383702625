import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { API } from "../../api/api";

const initialState = {
  types: {
    data: [],
    status: "idle",
    error: null,
  },
  styles: {
    data: [],
    status: "idle",
    error: null,
  },
  config: {
    isNew: true,
    configId: "",
    styleId: "",
    data: {
      code: "",
      label: "",
      description: "",
      data: {},
    },
    status: "idle",
    error: null,
  },
  questionsStyle: {
    data: [],
    status: "idle",
    error: null,
  },
};

export const fetchTypes = createAsyncThunk("attributeSet/fetchTypes", async () => {
  let data;
  try {
    const response = await API.get("/question/types");

    data = await response.data.payload;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const fetchStyles = createAsyncThunk("attributeSet/fetchStyles", async (param) => {
  let data;
  try {
    const response = await API.get(`/question/style/${param}`);
    data = await response.data.payload;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const fetchConfig = createAsyncThunk("attributeSet/fetchConfig", async (param) => {
  let data;

  try {
    const response = await API.get(`/attribute/question/style/${param}`);

    data = await response.data.payload;
    if (response.status === 200) {
      return { data, styleId: param };
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const sendConfig = createAsyncThunk("attributeSet/sendConfig", async (param) => {
  const { styleId, code, label, description, data: configData } = param;
  const id = toast.loading("Please wait...");
  try {
    const dataPost = {
      code,
      label,
      description,
      questionStyle: styleId,
      data: JSON.stringify(configData),
    };

    const response = await API.post("/attribute", dataPost);

    const data = await response.data;

    if (response.status === 200) {
      toast.update(id, {
        render: "Attribute set created",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      return data;
    }

    throw new Error(response.statusText);
  } catch (err) {
    console.log(err.message);
    toast.update(id, {
      render: "somthing went wrong",
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });
    return Promise.reject(err.message);
  }
});

export const updateConfig = createAsyncThunk("attributeSet/updateConfig", async (param) => {
  const { styleId, configId, code, label, description, data: configData } = param;
  const id = toast.loading("Please wait...");
  try {
    const dataPost = {
      code,
      label,
      description,
      questionStyle: styleId,
      data: JSON.stringify(configData),
    };

    const response = await API.post(`/attribute/${configId}`, dataPost);

    const data = await response.data;

    if (response.status === 200) {
      toast.update(id, {
        render: "Attribute set updated",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      return data;
    }

    throw new Error(response.statusText);
  } catch (err) {
    console.log(err.message);
    toast.update(id, {
      render: "somthing went wrong",
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });

    return Promise.reject(err.message);
  }
});

export const deleteConfig = createAsyncThunk("attributeSet/deleteConfig", async (param) => {
  try {
    const response = await API.delete(`/attribute/${param}`);

    const data = await response.data;

    if (response.status === 200) {
      return data;
    }

    throw new Error(response.statusText);
  } catch (err) {
    console.log(err.message);
    return Promise.reject(err.message);
  }
});

export const fetchQuestionsStyle = createAsyncThunk(
  "attributeSet/fetchQuestionsStyle",
  async (param) => {
    try {
      let levels = await API.get("/levels").then((data) => data.data.payload);

      const worlds = [];
      for (let index = 0; index < levels.length; index++) {
        try {
          const response = await API.get(`/worlds/${levels[index].id}`);

          if (response.status === 200) {
            const data = await response.data.payload;

            worlds.push(...data.map((data) => ({ ...data, level: levels[index] })));
          }
        } catch (err) {}
      }
      // get all games
      const games = [];
      for (let index = 0; index < worlds.length; index++) {
        try {
          const response = await API.get(`/games/${worlds[index].id}`);

          if (response.status === 200) {
            const data = await response.data.payload;
            games.push(...data.map((data) => ({ ...data, world: worlds[index] })));
          }
        } catch (err) {}
      }

      // get all questions
      const questions = [];
      for (let index = 0; index < games.length; index++) {
        try {
          const response = await API.get(`/questions/${games[index].id}`);

          if (response.status === 200) {
            const data = await response.data.payload;

            questions.push(...data.map((data) => ({ ...data, game: games[index] })));
          }
        } catch (err) {}
      }

      // filter questions with specified style
      const questionsEdit = questions.filter((question) => question.question_style.id === param);
      return questionsEdit;
    } catch (err) {
      console.log(err.message);
      return Promise.reject(err.message);
    }
  }
);

const slice = createSlice({
  name: "attributeSet",
  initialState,
  reducers: {
    headerChangeHandler: (state, action) => {
      state.config.code = action?.payload?.code;
      state.config.description = action?.payload?.description;
      state.config.label = action?.payload?.label;
    },
  },
  extraReducers: {
    [fetchTypes.pending]: (state) => {
      state.types.status = "loading";
    },
    [fetchTypes.fulfilled]: (state, action) => {
      state.config.styleId = "";
      state.config.configId = "";
      state.config.isNew = true;
      state.types.status = "succeeded";
      state.types.data = action.payload
        ?.map((type) => ({ value: type.id, option: type.name }))
        .sort((a, b) => {
          if (a.option < b.option) {
            return -1;
          }
          if (a.option > b.option) {
            return 1;
          }
          return 0;
        });
    },
    [fetchTypes.rejected]: (state, action) => {
      state.types.status = "failed";
      state.config.styleId = "";
      state.config.configId = "";
      state.config.isNew = true;
      state.types.error = action.payload;
    },

    [fetchStyles.pending]: (state) => {
      state.styles.status = "loading";
    },
    [fetchStyles.fulfilled]: (state, action) => {
      state.styles.status = "succeeded";
      state.config.styleId = "";
      state.config.configId = "";
      state.config.isNew = true;
      state.styles.data = action.payload
        ?.map((style) => ({
          id: style.id,
          option: style.description,
        }))
        .sort((a, b) => {
          if (a.option < b.option) {
            return -1;
          }
          if (a.option > b.option) {
            return 1;
          }
          return 0;
        });
    },
    [fetchStyles.rejected]: (state, action) => {
      state.styles.status = "failed";
      state.config.status = "idle";
      state.config.styleId = "";
      state.config.configId = "";
      state.config.isNew = true;
      state.styles.data = [];
      state.styles.error = action.payload;
    },

    [fetchConfig.pending]: (state) => {
      state.config.status = "loading";
    },
    [fetchConfig.fulfilled]: (state, action) => {
      state.config.status = "succeeded";
      state.config.isNew = false;
      state.config.styleId = action?.payload?.styleId || "";
      state.config.configId = action?.payload?.data?.id || "";

      state.config.data.data = JSON.parse(action?.payload?.data?.data) || {};
      state.config.data.code = action?.payload?.data?.code || "";
      state.config.data.description = action?.payload?.data?.description || "";
      state.config.data.label = action?.payload?.data?.label || "";
    },
    [fetchConfig.rejected]: (state, action) => {
      state.config.status = "failed";

      state.config.configId = "";
      state.config.isNew = true;
      state.config.styleId = action.meta.arg;

      state.config.data.data = {};
      state.config.data.code = "";
      state.config.data.description = "";
      state.config.data.label = "";
    },

    [deleteConfig.pending]: (state) => {
      state.config.status = "loading";
    },
    [deleteConfig.fulfilled]: (state, action) => {
      state.config.status = "succeeded";

      state.config.styleId = "";
      state.config.configId = "";
      state.config.isNew = true;
      state.styles.data = [];
      state.config.data.data = {};
      state.config.data.code = "";
      state.config.data.description = "";
      state.config.data.label = "";
    },
    [deleteConfig.rejected]: (state, action) => {
      state.config.status = "failed";
    },
    [deleteConfig.pending]: (state) => {
      state.config.status = "loading";
    },
    [updateConfig.pending]: (state) => {
      state.config.status = "loading";
    },
    [updateConfig.fulfilled]: (state, action) => {
      state.config.status = "succeeded";

      state.config.configId = "";
      state.config.isNew = true;
      state.config.styleId = "";
      state.styles.data = [];

      state.config.data.data = {};
      state.config.data.code = "";
      state.config.data.description = "";
      state.config.data.label = "";
    },
    [updateConfig.rejected]: (state, action) => {
      state.config.status = "failed";
    },
    [fetchQuestionsStyle.pending]: (state) => {
      state.questionsStyle.status = "loading";
    },
    [fetchQuestionsStyle.rejected]: (state, action) => {
      state.questionsStyle.status = "failed";
    },
    [fetchQuestionsStyle.fulfilled]: (state, action) => {
      state.questionsStyle.status = "succeeded";
      state.questionsStyle.data = action?.payload;
    },
  },
});

export const reducer = slice.reducer;
export const { headerChangeHandler } = slice.actions;

export default slice;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api/api";

export const getImagesKey = createAsyncThunk(
  "images/getImagesKey",
  async (object, { getState, rejectWithValue }) => {
    try {
      const data = await API.get("/images");
      return data.data.payload;
    } catch (error) {}
  }
);

export const createImage = createAsyncThunk(
  "images/createImage",
  async (newImage, { getState, rejectWithValue }) => {
    try {
      const data = await API.post("/images", newImage);
      return data.data.payload;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const slice = createSlice({
  name: "images",
  initialState: {
    imagesKey: [],
    newImage: {
      status: null,
      error: null,
      data: {},
    },
    status: null,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [getImagesKey.pending]: (state, action) => {
      state.status = "loading";
    },
    [getImagesKey.fulfilled]: (state, action) => {
      state.status = "success";
      state.imagesKey = action.payload;
    },
    [getImagesKey.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
    [createImage.pending]: (state, action) => {
      state.newImage.status = "loading";
    },
    [createImage.fulfilled]: (state, action) => {
      state.newImage.status = "success";
      state.newImage.data = action.payload;
    },
    [createImage.rejected]: (state, { payload }) => {
      state.newImage.status = "failed";
      state.newImage.error = payload;
    },
  },
});
export default slice.reducer;

import React from "react";
import { AuthProvider } from "./contexts/JWTAuthContext";
import "./main.scss";
import routes, { RenderRoutes } from "./routes";
function App() {
  return (
    <AuthProvider>
      <RenderRoutes routes={routes} />
    </AuthProvider>
  );
}

export default App;
